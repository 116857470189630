/**
 *  Styling for the NewTask component that allows users to create a new task.
 */

.new-task
{
  // Create a basic layout that allows maximum room for the input field.
  display: grid;
  grid-template-columns: auto max-content;

  // Keep some distance between the items.
  gap: 1em;

  // Add a little bit of vertical space.
  margin: 1em 0;

  // Increase font size for the main input to draw some attention to it.
  font-size: 1.15em;
}