/**
 *  This file sets all properties related to fonts.
 */

// Import dependencies.
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,500;1,700&display=swap');

:root
{
  // We want to use easy to read sans-serif fonts.
  font-family: 'Open Sans', sans-serif;
}