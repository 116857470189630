/**
 *  Styling for the Task component that shows a single task.
 */

.task
{
  // Create a basic layout that allows maximum room for the title of the task.
  display: grid;
  grid-template-columns: max-content auto max-content max-content;

  // Vertically center all elements.
  align-items: center;

  // Keep a little space between the different elements.
  gap: 1ch;

  // Add some padding to give each task some body.
  padding: .5em 1em;

  // Set the parameters for the default background color.
  --bg-hue: 0;
  --bg-saturation: 0%;
  --bg-lightness: 75%;
  --bg-opacity: 0.25;

  // Give each task rounded edges for a friendlier look.
  border-radius: .25em;

  // List which properties to animate.
  transition-duration: var(--animation-speed-slow);
  transition-property: filter, text-decoration, background-color;

  // Target the title.
  .title
  {
    // Use Flexbox to create a layout for this.
    display: flex;

    // Target both the span that display the regular title and the input that
    // allows for editing the title. This allows us to keep them exactly in the
    // same place.
    span, input
    {
      // Give them both equal borders and padding.
      border-width: 1px;
      border-style: solid;
      padding: 0.5em;

      // Use as much space as we can get.
      flex-grow: 1;
    }

    // Target the regular text.
    span
    {
      // Don't show the regular text's borders.
      border-color: transparent;

      // Hide entirely when empty.
      &:empty { display: none; }
    }
  }

  // Target the buttons.
  > button
  {
    // Install a background color that matches the task's background.
    background-color: hsla(
      var(--bg-hue),
      var(--bg-saturation),
      50%,
      var(--bg-opacity)
    );

    // And a darker icon color.
    color: hsl(
      var(--bg-hue),
      var(--bg-saturation),
      10%
    );
  }

  // Target the task when in editing mode.
  &.editing
  {
    // Make the task darker while editing.
    --bg-lightness: 25%;
  }

  // Target the task when marked as done.
  &.done
  {
    // Make the task darker and much more transparent so that it does not draw
    // as much attention.
    --bg-lightness: 10%;
    filter: opacity(50%);

    // Show the text as strikken through.
    text-decoration: line-through;
  }

  // Install the background color.
  background-color: hsla(
    var(--bg-hue),
    var(--bg-saturation),
    var(--bg-lightness),
    var(--bg-opacity)
  );
}

// Add a class to hide elements.
.hidden { display: none; }
